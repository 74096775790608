import React, { Component } from "react";
import { Redirect } from "react-router-dom";
// import FormHandler from 'AdminApp/common/script/services/crud/FormHandler';
import FormComponent from "AdminApp/common/script/components/crud/FormComponent";
import ModalDialog from "AdminApp/elements/modal/ModalDialog";

export default class FormSubmitWrapper extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showInfoModal: false,
      infoMessage: false,
    };
    // Initial state
    this.formHandler = props.formHandler;

    this.formHandler.on("submitSuccess", (data) => {
      let customMessage = this.props.getModalMessage(data);
      this.setState(
        {
          showInfoModal: true,
          infoMessage: true,
          textMessage:
            customMessage || data.message || "The form is successfully saved.",
        },
        () => {
          this.props.customCloseFunction();
        }
      );

      if (props.onSuccess) {
        props.onSuccess(data);
        this.setState({
          showInfoModal: false,
        });
      } else {
        setTimeout(() => {
          this.setState({
            showInfoModal: false,
            redirect: true,
          });
        }, 3000);
      }
    });

    this.formHandler.on("submitError", (error) => {
      let data = error.responseJSON ? error.responseJSON : error;
      let customMessage = this.props.getModalMessage(data);
      this.setState({
        showInfoModal: true,
        infoMessage: false,
        textMessage:
          customMessage ||
          data.message ||
          "Oops, something is wrong, you can not finish this action.",
      });
      setTimeout(() => {
        this.setState({
          showInfoModal: false,
        });
      }, 3000);
    });
  }

  onClose() {
    this.setState({
      showInfoModal: false,
    });
  }

  goBack() {
    this.setState(
      {
        redirect: true,
      },
      () => {
        this.props.customCloseFunction();
        if (this.props.to) {
          if (typeof this.props.to === "function") {
            this.props.to();
            return null;
          } else {
            return <Redirect to={this.props.to} />;
          }
        }
      }
    );
  }

  _getCancelFormButton() {
    if (this.props.noCancelButton) {
      return null;
    }

    return (
      <button
        type="button"
        onClick={() => this.goBack()}
        style={{ minWidth: 200, maxWidth: 240, width: "100%", marginLeft: 5 }}
        className="button-danger"
      >
        Cancel
      </button>
    );
  }

  render() {
    if (this.state.redirect && this.props.to) {
      return <Redirect to={this.props.to}></Redirect>;
    }

    const cancelButton = this._getCancelFormButton();

    let formHandler = this.formHandler;

    let successMessage = (
      <div style={{ display: "flex" }}>
        <p style={{ textAlign: "center" }}>{this.state.textMessage}</p>
        <i
          className="fa fa-check"
          aria-hidden="true"
          style={{
            color: "rgba(86, 181, 66, 0.8)",
            fontSize: 24,
            paddingLeft: 20,
          }}
        ></i>
      </div>
    );

    let errorMessage = (
      <div style={{ display: "flex" }}>
        <p style={{ textAlign: "center" }}>{this.state.textMessage}</p>
        <i
          className="fa fa-times"
          aria-hidden="true"
          style={{ color: "red", fontSize: 24, paddingLeft: 20 }}
        ></i>
      </div>
    );

    let disabledClass = this.props.disabledSubmit ? " disabled-btn" : "";

    return (
      <div style={{ width: "100%" }}>
        <FormComponent formHandler={formHandler}>
          {this.props.children}

          {this.props.renderActionButtons ? (
            this.props.renderActionButtons(this.formHandler.state.isLoading)
          ) : (
            <div
              className="formRow"
              style={{
                marginTop: 20,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                position: "relative",
                zIndex: 1,
              }}
            >
              <button
                type="submit"
                onClick={(e) => this.formHandler.handleSubmit(e)}
                className={"button-info" + disabledClass}
                style={{
                  minWidth: 200,
                  maxWidth: 240,
                  width: "100%",
                  marginRight: 5,
                }}
              >
                {this.props.buttonText}
              </button>
              {this.props.showBtn === true ? cancelButton : null}
            </div>
          )}
        </FormComponent>

        <ModalDialog
          show={this.state.showInfoModal}
          onClose={this.onClose.bind(this)}
          shouldCloseOnOverlayClick={false}
          shouldCloseOnEsc={false}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {this.state.infoMessage === true ? successMessage : errorMessage}
          </div>
        </ModalDialog>
      </div>
    );
  }
}

FormSubmitWrapper.defaultProps = {
  buttonText: "Save",
  getModalMessage: () => null,
  customCloseFunction: () => null,
  showBtn: true,
};
