import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import BreadcrumbsHelper from 'logic/helpers/BreadcrumbHelper';
import BreadcrumbHelper from '../../logic/helpers/BreadcrumbHelper';

export default class Breadcrumbs extends Component {

  constructor() {
    super();
    this.state = {
      crumbs: BreadcrumbsHelper.crumbs
    }

    BreadcrumbHelper.onUpdate((crumbs) => {
      this.setState({
        crumbs: crumbs
      })
    })

  }

  render() {

    let elements = [];
    elements.push(<Link key={'linkHome'} to="/" className="breadcrumb-item">Home</Link>);

    for(let i = 0; i < this.state.crumbs.length; i++) {
      let crumb = this.state.crumbs[i];

      if(crumb.link) {
        elements.push(<Link key={'link' + i} to={crumb.link} className="breadcrumb-item">{crumb.label}</Link>);
      } else {
        elements.push(<span key={'link' + i} className="breadcrumb-item active">{crumb.label}</span>);

      }
    }

    return (
      <nav className="breadcrumb" style={{height: 10,  background: 'transparent'}}>
        {elements}
      </nav>
    )
}
}
